import React from 'react';
import {useNavigate} from 'react-router-dom';
import BlogOne from '../assets/images/BlogPageOne/ImgOne.png';

const Highlights = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col gap-8'>
            <div className='text-[#111827] font-semibold text-[24px] leading-8'>
                Highlights
            </div>
            <div className='relative'>
                <div>
                    <img src={BlogOne} alt='' className='w-full h-full rounded-[16px] object-cover' />
                </div>
                <div className='absolute inset-0 rounded-[16px] bg-gradient-to-r from-black/75 via-black/55 to-black/20'></div>
                <div className='absolute inset-0 top-2 left-2 md:top-8 md:left-8 lg:top-16 lg:left-16 flex flex-col gap-4 cursor-pointer group' onClick={() => navigate('/blog-one')}>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#FFFFFF] font-normal group-hover:text-gray-400'>
                            22 August 2024
                        </div>
                        <div className='border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                            Investment
                        </div>
                    </div>
                    <div className='text-[#FFFFFF] font-semibold text-[20px] md:text-[28px] lg:text-[32px] leading-5 md:leading-7 lg:leading-[38px] group-hover:text-gray-400'>
                        The Magic of Compounding: Your Silent Wealth Builder
                    </div>
                    <div className='text-[#E5E7EB] font-normal md:text-[20px] lg:text-[16px] leading-4 lg:leading-6 group-hover:text-gray-400'>
                        Embark on a journey of financial prosperity that spans generations.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Highlights