import React from 'react';
import NavBar from '../components/NavBar.js';
import OurPricayPolicy from '../components/PrivacyPolicy/OurPricayPolicy.js';
import Introduction from '../components/PrivacyPolicy/Introduction.js';
import PolicyReview from '../components/PrivacyPolicy/PolicyReview.js';
import Consent from '../components/PrivacyPolicy/Consent.js';
import SecurityPractices from '../components/PrivacyPolicy/SecurityPractices.js';
import Cookies from '../components/PrivacyPolicy/Cookies.js';
import DataRetention from '../components/PrivacyPolicy/DataRetention.js';
import Disclaimers from '../components/PrivacyPolicy/Disclaimers.js';
import Footer from '../components/Footer.js';
import Information from '../components/PrivacyPolicy/Information.js';
import Purpose from '../components/PrivacyPolicy/Purpose.js';
import SharingInfo from '../components/PrivacyPolicy/SharingInfo.js';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Networth Tracker - Privacy Policy</title>
                <meta name="description" content="Read our privacy policy to understand how we handle and protect your personal information when using Networth Tracker." />
                <link rel="canonical" href="/privacy-policy" />
            </Helmet>
            <div className='tracking-wide'>
                <NavBar />
                <OurPricayPolicy />
                <div className='flex flex-col gap-6 md:gap-12 py-7 md:py-10 xl:py-24'>
                    <Introduction />
                    <PolicyReview />
                    <Information />
                    <Consent />
                    <Purpose />
                    <SecurityPractices />
                    <SharingInfo />
                    <Cookies />
                    <DataRetention />
                    <Disclaimers />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicy;