import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
// import Product from '../components/Product';
// import Solutions from '../components/Solutions';
import Different from '../components/Different';
import BlogPost from '../components/BlogPost';
import Footer from '../components/Footer';
import Header from '../components/Header';
import NetworthTracker from '../components/NetworthTracker';
import FAQs from '../components/FAQs';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
    const productRef = useRef(null);
    const solutionsRef = useRef(null);
    const footerRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash && (footerRef.current || productRef.current || solutionsRef.current)) {
            // setTimeout(() => {
            if (hash === '#CTO' && footerRef.current) {
                footerRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (hash === '#product' && productRef.current) {
                productRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (hash === '#solutions' && solutionsRef.current) {
                solutionsRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            // }, 100);
        }
    }, [location]);

    return (
        <>
            <Helmet>
                <title>Networth Tracker - Empower Your Wealth: Track, Automate, Prosper!</title>
                <meta name="description" content="Tracking your finances is just the start. Unlock more with seamless tracking with our advanced AI-powered analytics, advisory and more." />
                <link rel="canonical" href="/" />
            </Helmet>
            <div className='tracking-wide'>
                <Header />
                <NavBar productRef={productRef} solutionsRef={solutionsRef} text='homePage' />
                <NetworthTracker />
                {/* <div ref={productRef}>
                <Product />
            </div> */}
                {/* <div ref={solutionsRef}>
                <Solutions />
            </div> */}
                <Different />
                <FAQs />
                <BlogPost />
                <div ref={footerRef}>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default HomePage;
