import React, { useState } from 'react';
import Mail from '../assets/images/Icons/mail.png';
import { postQuery } from '../api/discover';
import toast, { Toaster } from 'react-hot-toast';
import RegistrationSuccess from '../modals/RegistrationSuccess';
import NewsLetterModal from '../modals/NewsLetterModal';
import AlreadySubscribed from '../modals/AlreadySubscribed';
import AlreadyWaitlisted from '../modals/AlreadyWaitlisted';

const EmailInput = (props) => {
    const [data, setData] = useState({
        email: "",
        type: props.text === "Join Waitlist" ? "WAITLIST" : "NEWSLETTER"
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
    const [showAlreadyWaitlisted, setShowAlreadyWaitlisted] = useState(false);
    const [showNewsletterSuccess, setShowNewsletterSuccess] = useState(false);
    const [showAlreadySubscribed, setShowAlreadySubscribed] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        if (isSubmitting) return;

        if (data.email.length === 0) {
            toast.error("Enter your email");
            return;
        }

        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regex.test(data.email)) {
            toast.error("Invalid Email");
            setData({ ...data, email: "" });
            return;
        }

        setIsSubmitting(true);
        try {
            const res = await postQuery(data);
            console.log('result', res);
            if (res.status === 200 || res.status === 201) {
                setData({ ...data, email: "" });
                props.text === "Join Waitlist" ? setShowRegisterSuccess(true) : setShowNewsletterSuccess(true);
            } else {
                toast.error("Please try again.");
            }
        } catch (error) {
            if (error.response.status === 409) {
                setData({ ...data, email: "" });
                props.text === "Join Waitlist" ? setShowAlreadyWaitlisted(true) : setShowAlreadySubscribed(true);
            } else {
                toast.error(error.message || "Something went wrong. Please try again.");
            }
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <div>
            <Toaster />
            <form onSubmit={handleSubmit} className='flex flex-col md:flex-row gap-4 md:gap-2 lg:gap-4'>
                <div className='relative'>
                    <img src={Mail} alt='' className='absolute lg:w-5 lg:h-5 top-4 left-3' />
                    <input
                        type='email'
                        placeholder='Enter your email'
                        className='border rounded-[8px] pl-12 pr-3 py-3 w-full md:w-80 h-12 focus:outline-none'
                        name="email"
                        value={data.email}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        required
                    />
                </div>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className='w-full md:w-fit bg-[#0072DE] text-white rounded-[8px] px-[20px] py-[12px] flex items-center justify-center hover:cursor-pointer hover:opacity-70'
                >
                    {props.text}
                </button>
            </form>

            {showRegisterSuccess && (
                <RegistrationSuccess
                    showRegisterSuccess={showRegisterSuccess}
                    setShowRegisterSuccess={setShowRegisterSuccess}
                />
            )}
            {showAlreadyWaitlisted && (
                <AlreadyWaitlisted
                    showAlreadyWaitlisted={showAlreadyWaitlisted}
                    setShowAlreadyWaitlisted={setShowAlreadyWaitlisted}
                />
            )}
            {showNewsletterSuccess && (
                <NewsLetterModal
                    showNewsletterSuccess={showNewsletterSuccess}
                    setShowNewsletterSuccess={setShowNewsletterSuccess}
                />
            )}
            {showAlreadySubscribed && (
                <AlreadySubscribed
                    showAlreadySubscribed={showAlreadySubscribed}
                    setShowAlreadySubscribed={setShowAlreadySubscribed}
                />
            )}
        </div>
    );
}

export default EmailInput;
