import React from 'react';
import Mission from '../assets/images/Mission.png';
import Vision from '../assets/images/Vision.png';

const MisionVision = () => {
    return (
        <div className='px-5 xl:px-[120px] flex flex-col'>
            <div className='py-7 md:py-10 xl:py-24 flex flex-col-reverse xl:flex-row gap-8'>
                <div className='w-full xl:w-[50%] px-2 xl:px-8 flex items-center justify-center'>
                    <img src={Mission} alt='' />
                </div>
                <div className='w-full xl:w-[50%] flex flex-col gap-4 px-2 xl:px-8 justify-center'>
                    <div className='text-[#0072DE] font-semibold text-[16px] leading-6 xl:px-8'>
                        Our Mission
                    </div>
                    <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px] xl:px-8'>
                        Revolutionizing Personal Finance: From Tracking to Neo-Banking
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-8'>
                        Our mission is to revolutionize personal finance through an automated one-stop platform. Beginning with effortless net worth tracking, we will seamlessly expand into data analytics, investment/expense optimization advisory, and ultimately neo-banking services - bridging the gap from disorganized finances to structured money management bliss.
                    </div>
                </div>
            </div>
            <div className='py-7 md:py-10 xl:py-24 flex flex-col-reverse xl:flex-row-reverse gap-8'>
                <div className='w-full xl:w-[50%] px-2 xl:px-8 flex items-center justify-center'>
                    <img src={Vision} alt='' />
                </div>
                <div className='w-full xl:w-[50%] flex flex-col gap-4 px-2 xl:px-8 justify-center'>
                    <div className='text-[#0072DE] font-semibold text-[16px] leading-6 xl:px-8'>
                        Our Vision
                    </div>
                    <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px] xl:px-8'>
                        Empowering Financial Freedom for All
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-8'>
                        Our vision is to be the trusted partner on every person's journey towards financial freedom. We strive to democratize access to sophisticated financial tools and services, leveling the playing field for all.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MisionVision;