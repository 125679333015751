import React from 'react';
import NavBar from '../components/NavBar';
import OurBlog from '../components/OurBlog';
import NewsLetter from '../components/NewsLetter';
import Footer from '../components/Footer';
import BlogContent from '../components/BlogContent';
import { Helmet } from 'react-helmet-async';

const Blog = () => {

    return (
        <>
            <Helmet>
                <title>Networth Tracker Blogs - Financial Tips and Insights</title>
                <meta name="description" content="Stay updated with the latest financial tips, trends, and insights from our experts. Read our blogs to enhance your personal finance knowledge." />
                <link rel="canonical" href="/blogs" />
            </Helmet>
            <div className='tracking-wide'>
                <NavBar />
                <OurBlog />
                <BlogContent />
                <NewsLetter />
                <Footer />
            </div>
        </>
    )
}

export default Blog