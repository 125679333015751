import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlogTwo from '../assets/images/BlogPageTwo/ImgOne.png';
import BlogThree from '../assets/images/BlogPageThree/ImgOne.png';

const Trending = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col gap-8'>
            <div className='text-[#111827] font-semibold text-[24px] leading-8'>
                Trending Now
            </div>
            <div className='flex flex-col lg:flex-row gap-8'>
                <div className='w-full lg:w-[50%] flex flex-col gap-4 group cursor-pointer' onClick={() => navigate('/blog-two')}>
                    <div>
                        <img src={BlogTwo} alt='' className='w-full h-full rounded-[16px] object-cover' />
                    </div>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            24 August 2024
                        </div>
                        <div className='border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                            Stock Market
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[20px] leading-7 group-hover:text-gray-600'>
                        Diversify Like Ambani: Build a Stronger Financial Future
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        Create a personalized diversification plan that aligns with your financial goals.
                    </div>
                </div>

                <div className='w-full lg:w-[50%] flex flex-col gap-4 group cursor-pointer' onClick={() => navigate('/blog-three')}>
                    <div>
                        <img src={BlogThree} alt='' className='w-full h-full rounded-[16px] object-cover' />
                    </div>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            26 August 2024
                        </div>
                        <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                            Finance
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[20px] leading-7 cursor-pointer group-hover:text-gray-600'>
                        Understanding the Time Value of Money
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        Make smarter financial choices and increase your wealth over time.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trending