import React from 'react'

const OurBlog = () => {
    return (
        <div className='px-5 lg:px-[120px]'>
            <div className='flex flex-col items-center justify-center py-7 md:py-10 xl:py-24 gap-4'>
                <div className='text-[#0072DE] font-semibold text-[16px] leading-6 text-center'>
                    Our blog
                </div>
                <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] text-center'>
                    Latest blog posts
                </div>
                <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center'>
                    Stay ahead of the curve with our latest blog releases
                </div>
            </div>
        </div>
    )
}

export default OurBlog