import React from 'react';
import Jash from '../assets/images/Jash.png';

const Story = () => {
    return (
        <div className='px-5 xl:px-[120px] py-7 md:py-10 xl:py-24 flex flex-col-reverse xl:flex-row gap-8'>
            <div className='w-full xl:w-[40%] flex justify-center items-center'>
                <img src={Jash} alt='' className='hidden xl:block' />
            </div>
            <div className='w-full xl:w-[60%] flex flex-col justify-center gap-4'>
                <div className='text-[#0072DE] font-semibold text-[16px] leading-6'>
                    Founder's Story
                </div>
                <div className='flex flex-col gap-4 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
                    <p>
                        After completing my Chartered Accountancy course, I stepped into the fast-paced world of investment banking, where I quickly became captivated by the intricacies of finance. However, it was my family's financial journey that truly ignited my passion. I discovered that my father, the sole breadwinner, was navigating the investment landscape based on advice from various sources, which led me to wonder: could we do better?
                    </p>
                    <div className='w-full flex justify-center items-center'>
                        <img src={Jash} alt='' className='xl:hidden' />
                    </div>
                    <p>
                        Driven by curiosity, I began to explore our family's finances in depth. This journey introduced me to the fascinating world of behavioral finance, revealing how emotions and biases can influence investment decisions. I crafted a structured asset allocation for our family's net worth, transitioning our investments to reliable platforms and diversifying into various asset classes. Over three years, we transformed our financial landscape, creating a clearer, more organized approach to our wealth.
                    </p>
                    <p>
                        This eye-opening experience inspired me to launch Networth Tracker. I realized there was a significant gap in tools for tracking family wealth, and I wanted to fill it. My mission is to empower individuals to take control of their financial destinies, optimizing their investments effortlessly. Join me on this journey to unlock the full potential of your wealth!
                    </p>
                </div>
                <div className='text-[#0072DE] font-semibold text-[16px] leading-6 text-right'>
                    Founder - Jash Koradia
                </div>
            </div>
        </div>
    )
}

export default Story;