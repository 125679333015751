import React from 'react';
import AvatarOne from '../assets/images/users/AvatarOne.png';
import JashAvatar from '../assets/images/Avatars/JashAvatar.png';
import KishanAvatar from '../assets/images/Avatars/KishanAvatar.png';
import VikrantAvatar from '../assets/images/Avatars/VikrantAvatar.png';
import ManojAvatar from '../assets/images/Avatars/ManojAvatar.png';
import KhyatiAvatar from '../assets/images/Avatars/KhyatiAvatar.png';
import HarshitAvatar from '../assets/images/Avatars/HarshitAvatar.png';

const Team = () => {
    return (
        <div className='px-5 lg:px-[120px] py-7 md:py-10 xl:py-24'>
            <div className='lg:px-8 flex flex-col gap-16'>
                <div className='flex flex-col gap-4 items-center justify-center text-center'>
                    <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px] lg:px-8'>
                        Meet our founding team
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 lg:px-8 w-full lg:w-[60%]'>
                        Meet our diverse team of talented professionals, united by their dedication to excellence and relentness pursuit of success.
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8'>
                    <div className='flex gap-4'>
                        <div>
                            <img src={JashAvatar} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Jash Koradia
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                CEO
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div>
                            <img src={AvatarOne} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Keshav Kumar
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                Backend Developer
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div>
                            <img src={KishanAvatar} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Kishan Kumar
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                Frontend Developer
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div>
                            <img src={ManojAvatar} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Manoj Kumar
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                Product Designer
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div>
                            <img src={VikrantAvatar} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Vikrant Singh Tomar
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                Backend Developer
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div>
                            <img src={KhyatiAvatar} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Khyati Gandhi
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                Jr. Product Designer
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div>
                            <img src={HarshitAvatar} alt='' className='h-fit lg:h-24 w-fit lg:w-24 object-cover' />
                        </div>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                                Harshit Dayal
                            </div>
                            <div className='justify-center text-[#4B5563] font-normal text-[14px] leading-5'>
                                AI/ML Engineer
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Team;