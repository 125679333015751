import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MobileLogin from './pages/MobileLogin';
import EmailLogin from './pages/EmailLogin';
import Dashboard from './pages/Dashboard';
import Modal from 'react-modal';
import DataPage from './pages/DataPage';
import ProductsAndServices from './pages/ProductsAndServices';
import Company from './pages/Company';
import Blog from './pages/Blog';
import BlogPageOne from './pages/BlogPages/BlogPageOne';
import BlogPageTwo from './pages/BlogPages/BlogPageTwo';
import BlogPageThree from './pages/BlogPages/BlogPageThree';
import ScrollToTop from './components/ScrollToTop';
import Product from './pages/Product';
import Solutions from './pages/Solutions';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';

Modal.setAppElement('#root');

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/company' element={<Company />}></Route>
          <Route path='/product' element={<Product />}></Route>
          <Route path='/solutions' element={<Solutions />}></Route>
          <Route path='/blogs' element={<Blog />}></Route>
          <Route path='/blog-one' element={<BlogPageOne />}></Route>
          <Route path='/blog-two' element={<BlogPageTwo />}></Route>
          <Route path='/blog-three' element={<BlogPageThree />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route path='products-services' element={<ProductsAndServices />} ></Route>
          <Route path='/mobile-login' element={<MobileLogin />}></Route>
          <Route path='/email-login' element={<EmailLogin />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/data' element={<DataPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
