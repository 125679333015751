import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Minus from '../assets/images/Icons/Minus.png';
import Plus from '../assets/images/Icons/Plus.png';
import FAQData from './data/FAQs.json';
import GridLeft from '../assets/images/GridLeft.png';
import GridRight from '../assets/images/GridRight.png';

const FAQs = () => {
  const navigate = useNavigate();
  const [openQuestions, setOpenQuestions] = useState([false]);

  const toggleQuestion = (index) => {
    const newOpenQuestions = [...openQuestions];
    newOpenQuestions[index] = !newOpenQuestions[index];
    setOpenQuestions(newOpenQuestions);
  };

  return (
    <div className='px-5 md:px-14 xl:px-[120px] pt-12 md:pt-24 pb-10 md:pb-[72px] bg-[#111827] relative'>
      <div className='relative flex flex-col xl:flex-row gap-12 z-10'>
        <div className='absolute lg:left-0 top-0 lg:top-4 opacity-15 z-0'>
          <img src={GridLeft} alt='' />
        </div>
        <div className='absolute bottom-0 lg:right-0 lg:top-4 opacity-15 z-0'>
          <img src={GridRight} alt='' />
        </div>
        <div className='flex flex-col gap-4 w-full xl:w-[40%]'>
          <div className='text-[#0079C0] font-semibold text-[16px] leading-6'>
            Support
          </div>
          <div className='text-[#FFFFFF] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px] w-full lg:w-[80%]'>
            Frequently asked questions
          </div>
          <div className='text-[#B0B0B0] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 w-full lg:w-[95%]'>
            For any unanswered questions, reach out to our support team via email. We'll respond as soon as possible to assist you.
          </div>
          <div className='bg-[#0079C0] rounded-[8px] h-fit w-fit px-[18px] py-[10px] text-[#FFFFFF] font-medium text-[16px] leading-6 hover:cursor-pointer hover:opacity-70 z-20' onClick={() => navigate('/contact-us')}>
            Contact Us
          </div>
        </div>
        <div className='flex flex-col w-full xl:w-[60%] z-20'>
          {FAQData.map((data, index) => (
            <div key={index} className='border-t py-4 lg:py-6 flex justify-between gap-4 cursor-pointer' 
                  onClick={() => toggleQuestion(index)}>
              <div className='flex flex-col gap-2 w-[90%]'>
                <div className='text-[#FFFFFF] font-medium text-[16px] md:text-[18px] leading-6 md:leading-7'>
                  {data.Question}
                </div>
                {openQuestions[index] && (
                  <div className='text-[#B0B0B0] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7'>
                    {data.Answer}
                  </div>
                )}
              </div>
              <div className='w-[10%]'>
                <div
                  className='hover:cursor-pointer h-6 w-6 flex items-center justify-center'
                >
                  {openQuestions[index] ? (
                    <img src={Minus} alt='Collapse' />
                  ) : (
                    <img src={Plus} alt='Expand' />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQs;