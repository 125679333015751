import React from 'react';
import NavBar from '../components/NavBar';
import AboutUs from '../components/AboutUs';
import MisionVision from '../components/MisionVision';
import Story from '../components/Story';
import Team from '../components/Team';
import NewsLetter from '../components/NewsLetter';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const Company = () => {
    return (
        <>
            <Helmet>
                <title>Networth Tracker - About Us</title>
                <meta name="description" content="Effortlessly track, manage, and optimize your finances online with automation. Our platform streamlines financial tasks, giving you real-time insights and control over your wealth." />
                <link rel="canonical" href="/company" />
            </Helmet>
            <div className='tracking-wide'>
                <NavBar />
                <AboutUs />
                <MisionVision />
                <Story />
                <Team />
                <NewsLetter />
                <Footer />
            </div>
        </>
    )
}

export default Company;