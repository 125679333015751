import React from 'react';
import GridLeft from '../assets/images/GridLeft.png';
import GridRight from '../assets/images/GridRight.png';

const FooterTop = () => {
  return (
    <div className='relative w-full bg-[#111827] text-[#FFFFFF] px-5 py-10 lg:px-20 lg:py-[104px] flex flex-col items-center justify-center gap-10 border-b border-[#FFFFFF] border-opacity-10 z-10'>
      <div className='absolute lg:left-0 top-0 opacity-15 z-0'>
        <img src={GridLeft} alt='' />
      </div>
      <div className='absolute bottom-0 lg:right-0 lg:top-0 opacity-15 z-0'>
        <img src={GridRight} alt='' />
      </div>
      <div className='font-bold text-[36px] md:text-[48px] text-center leading-[48px] md:leading-[64px] w-full lg:w-[50%]'>
        A Smarter Solution for Managing Your Money
      </div>
      <div className='font-normal text-[18px] text-center leading-8 w-full lg:w-[55%]'>
        Discover a better approach to managing your money with tools that help you budget effortlessly, track your spending, and plan for the future—all in one place.
      </div>
      <a href='https://calendly.com/jash-s-koradia/30min/' target='_blank' rel="noreferrer" className='flex'>
        <div className='bg-[#0079C0] h-fit w-fit rounded-[16px] p-4 font-bold text-[16px] leading-[24.8px] hover:cursor-pointer hover:bg-[#0079C0] hover:opacity-70 z-20'>
          Book a call with CEO
        </div>
      </a>
    </div>
  )
}

export default FooterTop;