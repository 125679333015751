import React from 'react'

const Popular = () => {
    return (
        <div className='flex flex-col gap-4'>
            <div className='text-[#111827] font-semibold text-[24px] leading-8'>
                Popular
            </div>
            <div className='flex flex-col'>
                <div className='flex flex-col gap-2 border-[#E5E7EB] border-b py-8 transition duration-300 ease-in-out hover:bg-gray-300 hover:p-3 hover:rounded-lg hover:cursor-pointer'>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            22 August 2024
                        </div>
                        <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[1px] bg-[#F9FAFB]'>
                            Investment
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[18px] leading-7'>
                        The Magic of Compounding: Your Silent Wealth Builder
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        Embark on a journey of financial prosperity that spans generations.
                    </div>
                </div>
                <div className='flex flex-col gap-2 border-[#E5E7EB] border-b py-8 transition duration-300 ease-in-out hover:bg-gray-300 hover:p-3 hover:rounded-lg hover:cursor-pointer'>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            24 August 2024
                        </div>
                        <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[1px] bg-[#F9FAFB]'>
                            Stock Market
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[18px] leading-7'>
                        Diversify Like Ambani: Build a Stronger Financial Future
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        Create a personalized diversification plan that aligns with your financial goals.
                    </div>
                </div>
                <div className='flex flex-col gap-2 border-[#E5E7EB] border-b py-8 transition duration-300 ease-in-out hover:bg-gray-300 hover:p-3 hover:rounded-lg hover:cursor-pointer'>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            26 August 2024
                        </div>
                        <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[1px] bg-[#F9FAFB]'>
                            Finance
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[18px] leading-7'>
                        Understanding the Time Value of Money
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        Make smarter financial choices and increase your wealth over time.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popular